$hamburger-layer-spacing: 10px;
$hamburger-layer-height: 1px;
$hamburger-layer-width: 40px;

.hamburger {

    $self: &;

    position: absolute;

    top: u($rule*2);
    right: u($rule*2);

    width: $hamburger-layer-width;

    display: inline-block;

    cursor: pointer;
    z-index: 9999;
    

    &.active {
        #{$self}__inner {
            transition-duration: 0.22s;
            transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

            &::before {
                transition: top 0.1s 0.25s ease-in,
                opacity 0.1s ease-in;
            }

            &::after {
                transition: bottom 0.1s 0.25s ease-in,
                transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }

            transform: rotate(225deg);
            transition-delay: 0.12s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            &::before {
                top: 0;
                opacity: 0;
                transition: top 0.1s ease-out,
                opacity 0.1s 0.12s ease-out;
            }

            &::after {
                bottom: 0;
                transform: rotate(-90deg);
                transition: bottom 0.1s ease-out,
                transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }


    &__box {
        width: 40px;
        height: 20px;
        padding-top: 12px;
        display: inline-block;
        position: relative;
    }

    &__inner {

        display: block;
        
        &,
        &::before,
        &::after {
            width: $hamburger-layer-width;
            height: $hamburger-layer-height;
            background-color: white;
            border-radius: 0;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;

            transition: all .3s ease;
        }
        
        &::before,
        &::after {
            content: "";
            display: block;
        }
        
        &::before {
            top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        }
        
        &::after {
            bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        }

    }

    &__label {
        color: $cc-white;
        font-size: 14px;
        letter-spacing: 1px;
        text-align: center;

        &__open {
            display: inline;
            
            .menu-open & {
                display: none;
            }
        }
        
        &__close {
            display: none;
            
            .menu-open & {
                display: inline;
            }
        }
    }

}