.tags {
    display: grid;

    &__image, &__overlay {
        grid-area: 1/2;
    }

    &__overlay {
        display: grid; 
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);

        padding: u($rule/2);
    }

    &__item {
        display: flex;

        font-size: 10px;
        font-weight: bold;

        justify-content: center;
        align-items: center;

        &[data-horizontal='left'] {
            grid-column: 1;
            
            justify-content: flex-start;
        }
        &[data-horizontal='center'] {
            grid-column: 2;
            
            justify-content: center;
        }
        &[data-horizontal='right'] {
            grid-column: 3;
            
            justify-content: flex-end;
        }
        
        &[data-vertical='top'] {
            grid-row: 1;
            
            align-items: flex-start;
        }
        &[data-vertical='center'] {
            grid-row: 2;
            
            align-items: center;
        }
        &[data-vertical='bottom'] {
            grid-row: 3;
            
            align-items: flex-end;
        }

    }
}