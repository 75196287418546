/* Settings: Fonts */

// Default
$font-sans:                             "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-serif:                            Georgia, "Times New Roman", Times, serif !default;
$font-code:                             Menlo, Monaco, Consolas, monospace, serif !default;

// Custom
$cc-font:                           "Gilroy", sans-serif !default; //400, 500, 700

@font-face {
    font-family: "Gilroy";
    src: url(../fonts/Gilroy-Bold.woff2) format('woff2'),
        url(../fonts/Gilroy-Bold.woff) format('woff');
    font-style: normal;
    font-weight: 700;
  }
  
  @font-face {
    font-family: "Gilroy";
    src: url(../fonts/Gilroy-Medium.woff2) format('woff2'),
        url(../fonts/Gilroy-Medium.woff) format('woff');
    font-style: normal;
    font-weight: 500;
  }
  
  @font-face {
    font-family: "Gilroy";
    src: url(../fonts/Gilroy-Regular.woff2) format('woff2'),
        url(../fonts/Gilroy-Regular.woff) format('woff');
    font-style: normal;
    font-weight: 400;
  }
