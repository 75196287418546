@charset "UTF-8";
/* Style */
/* Settings: Base */
/* Settings: Colors */
/* Colors: basic */
/* Colors: Custom */
/* Colors: Theme */
/* Settings: Fonts */
@font-face {
  font-family: "Gilroy";
  src: url(../fonts/Gilroy-Bold.woff2) format("woff2"), url(../fonts/Gilroy-Bold.woff) format("woff");
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: "Gilroy";
  src: url(../fonts/Gilroy-Medium.woff2) format("woff2"), url(../fonts/Gilroy-Medium.woff) format("woff");
  font-style: normal;
  font-weight: 500; }

@font-face {
  font-family: "Gilroy";
  src: url(../fonts/Gilroy-Regular.woff2) format("woff2"), url(../fonts/Gilroy-Regular.woff) format("woff");
  font-style: normal;
  font-weight: 400; }

/* Elements: Heading */
/* Elements: HR */
/* Elements: Image */
/* Elements: Link */
/* Elements: List */
/* Elements: Paragraph */
/* Elements: Selection */
/* Objects: Alert */
/* Components: Button */
/* Objects: FlexEmbed */
/* Objects: Form */
/* Objects: Grid */
/* Objects: Nav */
/* Objects: Structure */
/* Objects: Table */
/* Objects: Toggle */
/* Components: Fields */
/* Components: Modal */
/* Functions: Units */
/* Functions: SVG */
/* Functions: Throw */
/* Functions: Checks */
/* Functions: Truncate list */
/* Functions: Maps */
/* Functions: Maps */
/* Functions: Order index */
/* Functions: String */
/* Mixin: Arrow */
/* Mixin: Background SVG */
/* Mixin: Caret */
/* Mixin: Context */
/* Mixin: Dimensions */
/* Mixin: Family */
/* Mixin: Font-responsive */
/* Mixin: Font-size */
/* Mixin: Font-smoothing */
/* Mixin: Grid */
/* Mixin: Hover */
/* Mixin: Image rendering */
/* Mixin: Last-children */
/* Mixin: Line-height crop */
/* Mixin: Maps */
/* Mixin: ParentNthStatus */
/* Mixin: ParentState */
/* Mixin: ParentStatus */
/* Mixin: Position */
/* Mixin: Maps */
/* Mixin: Transition */
/* Mixin: Truncate */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  letter-spacing: 0.01em; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button {
  font-family: inherit; }

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important; } }

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

[data-aos='fade-up'] {
  transform: translate3d(0, 100px, 0); }

[data-aos='fade-down'] {
  transform: translate3d(0, -100px, 0); }

[data-aos='fade-right'] {
  transform: translate3d(-100px, 0, 0); }

[data-aos='fade-left'] {
  transform: translate3d(100px, 0, 0); }

[data-aos='fade-up-right'] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos='fade-up-left'] {
  transform: translate3d(100px, 100px, 0); }

[data-aos='fade-down-right'] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos='fade-down-left'] {
  transform: translate3d(100px, -100px, 0); }

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }

[data-aos='zoom-in'] {
  transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos='zoom-in-right'] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos='zoom-out'] {
  transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos='zoom-out-right'] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translate3d(0, 0, 0); }

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0); }

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0); }

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0); }

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0); }

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

/* IE11 */
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, [data-aos^=fade][data-aos^=fade], [data-aos^=zoom][data-aos^=zoom] {
    opacity: 1;
    transform: none !important; } }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s ease, visibility 0s ease .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s ease 0s, visibility 0s ease 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--html {
  padding: 6px; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in; }

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable='true'] {
  cursor: text; }

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff; }

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px; }

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

.fancybox-button:hover {
  color: #fff; }

.fancybox-button:focus {
  outline: none; }

.fancybox-button.fancybox-focus {
  outline: 1px dotted; }

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none; }

/* Fix IE11 */
.fancybox-button div {
  height: 100%; }

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%; }

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0; }

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none; }

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none; }

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998; }

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401; }

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1; }

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0; }

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden; }

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none; }

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px; }

.fancybox-navigation .fancybox-button div {
  padding: 7px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right); }

/* Caption */
.fancybox-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996; }

.fancybox-caption--separate {
  margin-top: -50px; }

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all; }

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

/* Loading indicator */
.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999; }

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg); } }

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1); }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1); }

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px; }
  .fancybox-slide--image {
    padding: 6px 0; }
  .fancybox-close-small {
    right: -6px; }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px; }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px; } }

/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs__list a:focus::before {
  opacity: .5; }

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px); } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.slick-dotted.slick-slider {
  @apply mb-0; }

.slick-slider {
  @apply mb-0; }
  .slick-slider .slick-slide:focus {
    @apply outline-none; }

.slick-track,
.slick-list {
  @apply h-full; }

.slick-dots {
  @apply static text-left; }

/* HR */
hr {
  margin-top: 32px;
  margin-bottom: 31px;
  color: #bdbdbd;
  border: 0;
  border-bottom: 1px solid; }

/* Image */
img {
  max-width: 100%;
  height: auto; }

figure > img {
  display: block; }
  figure > img.img--cover {
    object-fit: cover;
    width: 100%;
    height: 100%; }

/* Link */
a {
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  color: inherit;
  text-decoration: none; }
  a:hover {
    text-decoration: none;
    color: #272424; }

.link {
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  color: #272424;
  text-decoration: none;
  padding-bottom: 5px;
  position: relative;
  font-weight: 500; }
  .link:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #272424;
    content: '';
    transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1), background 0.6s ease;
    transform: scaleX(1);
    transform-origin: left; }
  .link:hover, .link:focus, .link:active {
    color: #272424;
    text-decoration: none; }
    .link:hover:before, .link:focus:before, .link:active:before {
      transform: scaleX(0);
      transform-origin: right; }

/* List */
ol,
ul {
  margin-bottom: 32px;
  padding-left: 32px; }
  ol li,
  ul li {
    margin-bottom: 5px;
    list-style-type: '\2014'; }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-top: 4px;
    margin-bottom: 4px; }

ul {
  list-style-type: disc; }

ul ul {
  list-style-type: circle; }

ul ul ul {
  list-style-type: square; }

ol {
  list-style-type: decimal; }

ol ol {
  list-style-type: upper-alpha; }

ol ol ol {
  list-style-type: lower-roman; }

ol ol ol ol {
  list-style-type: lower-alpha; }

dt {
  font-weight: 700; }

/* Selection */
::selection {
  color: #ffffff;
  background-color: #272424; }

/* Heading */
.title {
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
  font-style: normal;
  word-break: break-word;
  font-size: 40.8px;
  line-height: 1.2; }
  .fonts-loaded .title {
    font-family: "Gilroy", sans-serif; }
  .title small {
    font-weight: 500; }
  @media screen and (min-width: 48em) {
    .title {
      font-size: 85px;
      line-height: 1.1; } }

.title--beta {
  font-size: 40.8px;
  line-height: 1.2; }
  @media screen and (min-width: 48em) {
    .title--beta {
      font-size: 85px;
      line-height: 1.1; } }

.title--charlie {
  font-size: 39px;
  line-height: 1.2; }
  @media screen and (min-width: 48em) {
    .title--charlie {
      font-size: 60px;
      line-height: 1.2; } }

.title--delta {
  font-size: 48px;
  line-height: 1.2; }

.title--echo {
  font-size: 32px;
  line-height: 1.2; }

.title--foxtrot {
  font-size: 18px; }

/* Paragraph */
p {
  margin-bottom: 32px; }

.Lead {
  font-size: 18px; }

/* Main */
html {
  background-color: #ffffff;
  color: #000000;
  font-family: "Gilroy", sans-serif;
  height: 100%;
  scroll-behavior: smooth; }
  html.fonts-loaded {
    font-family: "Gilroy", sans-serif; }
  html.hide-scroll {
    overflow: hidden; }

body {
  min-height: 100%;
  position: relative;
  overflow-x: hidden; }

.hide {
  display: none; }

.inactive {
  opacity: .4;
  pointer-events: none; }

.wrap {
  position: relative;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 32px;
  max-width: 1500px;
  box-sizing: content-box; }
  @media screen and (min-width: 37.5em) {
    .wrap {
      padding: 0 10%; } }
  @media screen and (min-width: 120em) {
    .wrap {
      padding: 0 15%; } }
  .wrap--large {
    max-width: 1650px; }
    @media screen and (min-width: 37.5em) {
      .wrap--large {
        padding: 0 6%; } }
    @media screen and (min-width: 120em) {
      .wrap--large {
        padding: 0 10%; } }
  @media screen and (min-width: 37.5em) {
    .wrap--small {
      padding: 0 15%; } }
  @media screen and (min-width: 120em) {
    .wrap--small {
      padding: 0 25%; } }
  @media screen and (max-width: 37.4375em) {
    .wrap .wrap {
      padding: 0; } }
  @media screen and (min-width: 37.5em) {
    .wrap .wrap--small {
      padding: 0 15%; } }
  @media screen and (min-width: 64em) {
    .wrap .wrap--small {
      padding: 0 20%; } }

.main--first-child .section:first-child {
  position: relative; }
  .main--first-child .section:first-child:before {
    content: '';
    position: absolute;
    top: -32px;
    left: -16px;
    right: -16px;
    height: 32px;
    z-index: 99;
    margin: 0 32px;
    box-sizing: border-box; }
    @media screen and (min-width: 48em) {
      .main--first-child .section:first-child:before {
        top: -64px;
        height: 64px;
        left: 0;
        right: 0;
        margin: 0 5%; } }
    @media screen and (min-width: 120em) {
      .main--first-child .section:first-child:before {
        margin: 0 auto;
        padding: 0 10%;
        max-width: 1500px;
        box-sizing: content-box; } }

.wysiwyg {
  margin-bottom: 32px;
  /* Heading */ }
  .wysiwyg--center {
    text-align: center; }
  .wysiwyg--no-margin {
    margin-bottom: 0; }
    .wysiwyg--no-margin p {
      margin-bottom: 0; }
  .wysiwyg a {
    transition-property: background-color, color;
    transition-duration: 200ms;
    transition-timing-function: linear;
    color: #272424;
    text-decoration: none; }
    .wysiwyg a:hover, .wysiwyg a:focus, .wysiwyg a:active {
      color: #272424;
      text-decoration: none; }
  .wysiwyg ol,
  .wysiwyg ul {
    margin-bottom: 32px;
    padding-left: 32px; }
    .wysiwyg ol li,
    .wysiwyg ul li {
      margin-bottom: 5px;
      list-style-type: '\2014'; }
    .wysiwyg ol ol,
    .wysiwyg ol ul,
    .wysiwyg ul ol,
    .wysiwyg ul ul {
      margin-top: 4px;
      margin-bottom: 4px; }
  .wysiwyg ul {
    list-style-type: disc; }
  .wysiwyg ul ul {
    list-style-type: circle; }
  .wysiwyg ul ul ul {
    list-style-type: square; }
  .wysiwyg ol {
    list-style-type: decimal; }
  .wysiwyg ol ol {
    list-style-type: upper-alpha; }
  .wysiwyg ol ol ol {
    list-style-type: lower-roman; }
  .wysiwyg ol ol ol ol {
    list-style-type: lower-alpha; }
  .wysiwyg dt {
    font-weight: 700; }
  .wysiwyg strong {
    font-weight: bold; }
  .wysiwyg p {
    margin-bottom: 16px;
    letter-spacing: 1px;
    line-height: 32px; }
  .wysiwyg h1, .wysiwyg h2, .wysiwyg h3, .wysiwyg h4, .wysiwyg h5, .wysiwyg h6 {
    font-family: "Gilroy", sans-serif;
    font-weight: 500;
    font-style: normal;
    margin-bottom: 16px; }
  .wysiwyg h1 {
    margin-bottom: 64px;
    font-size: 38.4px;
    line-height: 1.2; }
    @media screen and (min-width: 37.5em) {
      .wysiwyg h1 {
        font-size: 48px;
        line-height: 1.2; } }
    @media screen and (min-width: 120em) {
      .wysiwyg h1 {
        font-size: 96px;
        line-height: 1.1; } }
  .wysiwyg h2 {
    font-size: 42.5px;
    line-height: 1.2; }
    @media screen and (min-width: 48em) {
      .wysiwyg h2 {
        font-size: 85px;
        line-height: 1.1; } }
  .wysiwyg h3 {
    font-size: 60px;
    line-height: 1.2; }
  .wysiwyg h4 {
    font-size: 48px;
    line-height: 1.2; }
  .wysiwyg h5 {
    font-size: 32px;
    line-height: 1.2; }
  .wysiwyg h6 {
    font-size: 18px; }

/* Alert */
.Alert {
  position: relative;
  margin-bottom: 32px;
  padding: 1em 1.33333em 1em 1.33333em;
  border: 1px solid;
  border-radius: 2px; }
  .Alert.is-dismissable {
    padding-right: 42.66667px; }
  .Alert p,
  .Alert ul {
    margin-bottom: 0; }
    .Alert p + p,
    .Alert p + ul,
    .Alert ul + p,
    .Alert ul + ul {
      margin-top: 32px; }
  .Alert a:not(.Button--close) {
    font-weight: bold; }
    .Alert a:not(.Button--close):hover {
      text-decoration: underline; }

/* Nav */
.Nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex: 0 1 auto; }
  @media screen and (max-width: 64em) {
    .Nav-list {
      flex-direction: column; } }

.Nav-item {
  margin: 0;
  list-style: none; }
  .Nav-item.has-dropdown {
    position: relative; }
    @media screen and (min-width: 105.6875em) {
      .Nav-item.has-dropdown:hover .Nav-dropdown {
        opacity: 1;
        visibility: visible; }
        .Nav-item.has-dropdown:hover .Nav-dropdown::before {
          height: 30px; } }

.Nav-link {
  position: relative;
  user-select: none;
  display: block;
  font-size: 13px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #272424;
  text-decoration: none;
  margin-left: 30px;
  padding-bottom: 5px; }
  .Nav-link:hover, .Nav-link:focus, .Nav-link:active {
    color: #272424; }
  .Nav-link.is-active {
    color: #272424; }

.Nav-dropdown {
  position: absolute;
  opacity: 0;
  list-style: none;
  visibility: hidden;
  transition: opacity ease .3s, visibility ease .3s;
  top: 30px;
  text-align: left;
  margin: 0 auto;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  padding: 20px;
  background-color: #ffffff;
  width: 160px; }
  .Nav-dropdown.showDropdown {
    opacity: 1;
    visibility: visible; }
    .Nav-dropdown.showDropdown::before {
      height: 30px; }
  .Nav-dropdown-container .Nav-item a {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    border-bottom: 2px solid rgba(255, 255, 255, 0);
    transition: border-color .2s ease;
    line-height: 1;
    padding-bottom: 5px; }
  .Nav-dropdown-container .Nav-item a + a {
    padding-top: 10px; }

/* Button */
/*
 * 1. Corrects inability to style clickable `input` types in iOS.
 * 2. Normalize `box-sizing` across all elements that this component could be
 *    applied to.
 * 3. Inherit text color from ancestor.
 * 4. Inherit font styles from ancestor.
 * 5. Normalize `line-height`. For `input`, it can't be changed from `normal`
 *    in Firefox 4+.
 * 6. Prevent button text from being selectable.
 * 7. Make sure `input` will wrap text across multiple lines.
 *
 */
.Button {
  appearance: none;
  background: transparent;
  border-color: transparent;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0.66667em 1em 0.66667em 1em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: normal; }
  .Button::-moz-focus-inner {
    border: 0;
    padding: 0; }
  .Button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }
  .Button:active, .Button:focus, .Button:hover, .Button.is-active {
    color: inherit;
    text-decoration: none; }
  .Button:disabled, .Button.is-disabled {
    cursor: default;
    opacity: 0.6;
    pointer-events: none; }

/* FlexEmbed */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }
  .FlexEmbed::before {
    content: '';
    display: block;
    padding-bottom: 100%;
    width: 100%; }

.FlexEmbed-content,
.FlexEmbed-content > iframe,
.FlexEmbed-content > object,
.FlexEmbed-content > embed,
.FlexEmbed-content > video {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

img.FlexEmbed-content {
  object-fit: cover; }

.FlexEmbed--2by1:before {
  padding-bottom: 50%; }

.FlexEmbed--3by1:before {
  padding-bottom: 33.33333%; }

.FlexEmbed--3by2:before {
  padding-bottom: 66.66667%; }

.FlexEmbed--4by3:before {
  padding-bottom: 75%; }

.FlexEmbed--16by9:before {
  padding-bottom: 56.25%; }

/* Form */
.Form-item:not(.Form-item--action) {
  margin-bottom: 32px; }

.Form-label {
  display: inline-block;
  margin-bottom: 8px; }
  .Form-item:not(.is-required) .Form-label::after {
    content: ' - Optional';
    margin-left: 8px; }
    html[lang^="nl"] .Form-item:not(.is-required) .Form-label::after {
      content: ' - Optioneel'; }
    html[lang^="fr"] .Form-item:not(.is-required) .Form-label::after {
      content: ' - Facultatif'; }
  .Form-item.is-error .Form-label {
    color: #f44336; }
  .Form-item.is-success .Form-label {
    color: #4caf50; }

.Form-help {
  margin-top: 8px;
  color: #bdbdbd;
  font-size: 12px; }
  .Form-help:first-child {
    margin-top: 0;
    margin-bottom: 16px; }
  .Form-help :last-child {
    margin-bottom: 0; }
  .Form-help + .Form-help {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #e0e0e0; }

.Form-item.is-error .Form-help--validation {
  color: #f44336; }

.Form-item.is-success .Form-help--validation {
  color: #4caf50; }

.Form-controls {
  position: relative; }

@media screen and (min-width: 37.5em) {
  .Form--horizontal .Form-item {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start; }
  .Form--horizontal .Form-label {
    margin-top: 8px;
    margin-bottom: 0;
    padding-right: 32px;
    width: 33.33333%; }
  .Form--horizontal .Form-controls {
    width: 66.66667%; }
  .Form--horizontal .Form-item--action {
    padding-left: 33.33333%; } }

.Media-offscreen {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal; }

.Media-container {
  background: #000;
  box-sizing: border-box;
  font-family: 'Helvetica', Arial, serif;
  position: relative;
  text-align: left;
  text-indent: 0;
  vertical-align: top; }

.Media-container * {
  box-sizing: border-box; }

/* Hide native play button and control bar from iOS to favor plugin button */
.Media-container video::-webkit-media-controls,
.Media-container video::-webkit-media-controls-panel,
.Media-container video::-webkit-media-controls-panel-container,
.Media-container video::-webkit-media-controls-start-playback-button {
  -webkit-appearance: none;
  display: none !important; }

.Media-fill-container,
.Media-fill-container .Media-container {
  height: 100%;
  width: 100%; }

.Media-fill-container {
  background: transparent;
  margin: 0 auto;
  overflow: hidden;
  position: relative; }

.Media-container:focus {
  outline: none; }

.Media-iframe-overlay {
  height: 100%;
  position: absolute;
  width: 100%; }

.Media-embed,
.Media-embed body {
  background: #000;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

.Media-fullscreen {
  overflow: hidden !important; }

.Media-container-fullscreen {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000; }

.Media-container-fullscreen .Media-mediaelement,
.Media-container-fullscreen video {
  height: 100% !important;
  width: 100% !important; }

/* Start: LAYERS */
.Media-background {
  left: 0;
  position: absolute;
  top: 0; }

.Media-mediaelement {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0; }

.Media-poster {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1; }

:root .Media-poster-img {
  display: none; }

.Media-poster-img {
  border: 0;
  padding: 0; }

.Media-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0; }

.Media-layer {
  z-index: 1; }

.Media-overlay-play {
  cursor: pointer; }

.Media-overlay-button {
  background: url("../images/media/controls.svg") no-repeat;
  background-position: 0 -39px;
  height: 80px;
  width: 80px; }

.Media-overlay:hover > .Media-overlay-button {
  background-position: -80px -39px; }

.Media-overlay-loading {
  height: 80px;
  width: 80px; }

.Media-overlay-loading-bg-img {
  animation: Media-loading-spinner 1s linear infinite;
  background: transparent url("../images/media/controls.svg") -160px -40px no-repeat;
  display: block;
  height: 80px;
  width: 80px;
  z-index: 1; }

@keyframes Media-loading-spinner {
  100% {
    transform: rotate(360deg); } }

/* End: LAYERS */
/* Start: CONTROL BAR */
.Media-controls {
  bottom: 0;
  display: flex;
  height: 40px;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 0 10px;
  position: absolute;
  width: 100%;
  z-index: 3; }

.Media-controls:not([style*='display: none']) {
  background: rgba(255, 0, 0, 0.7);
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.35)); }

.Media-button,
.Media-time,
.Media-time-rail {
  font-size: 10px;
  height: 40px;
  line-height: 10px;
  margin: 0;
  width: 32px; }

.Media-button > button {
  background: transparent url("../images/media/controls.svg");
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  margin: 10px 6px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-decoration: none;
  width: 20px; }

/* :focus for accessibility */
.Media-button > button:focus {
  outline: dotted 1px #999; }

.Media-container-keyboard-inactive a,
.Media-container-keyboard-inactive a:focus,
.Media-container-keyboard-inactive button,
.Media-container-keyboard-inactive button:focus,
.Media-container-keyboard-inactive [role=slider],
.Media-container-keyboard-inactive [role=slider]:focus {
  outline: 0; }

/* End: CONTROL BAR */
/* Start: Time (Current / Duration) */
.Media-time {
  box-sizing: content-box;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  height: 24px;
  overflow: hidden;
  padding: 16px 6px 0;
  text-align: center;
  width: auto; }

/* End: Time (Current / Duration) */
/* Start: Play/Pause/Stop */
.Media-play > button {
  background-position: 0 0; }

.Media-pause > button {
  background-position: -20px 0; }

.Media-replay > button {
  background-position: -160px 0; }

/* End: Play/Pause/Stop */
/* Start: Progress Bar */
.Media-time-rail {
  direction: ltr;
  flex-grow: 1;
  height: 40px;
  margin: 0 10px;
  padding-top: 10px;
  position: relative; }

.Media-time-total,
.Media-time-buffering,
.Media-time-loaded,
.Media-time-current,
.Media-time-float,
.Media-time-hovered,
.Media-time-float-current,
.Media-time-float-corner,
.Media-time-marker {
  border-radius: 2px;
  cursor: pointer;
  display: block;
  height: 10px;
  position: absolute; }

.Media-time-total {
  background: rgba(255, 255, 255, 0.3);
  margin: 5px 0 0;
  width: 100%; }

.Media-time-buffering {
  animation: buffering-stripes 2s linear infinite;
  background: linear-gradient(-45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
  background-size: 15px 15px;
  width: 100%; }

@keyframes buffering-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

.Media-time-loaded {
  background: rgba(255, 255, 255, 0.3); }

.Media-time-current,
.Media-time-handle-content {
  background: rgba(255, 255, 255, 0.9); }

.Media-time-hovered {
  background: rgba(255, 255, 255, 0.5);
  z-index: 10; }

.Media-time-hovered.negative {
  background: rgba(0, 0, 0, 0.2); }

.Media-time-current,
.Media-time-buffering,
.Media-time-loaded,
.Media-time-hovered {
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0.15s ease-in all;
  width: 100%; }

.Media-time-buffering {
  transform: scaleX(1); }

.Media-time-hovered {
  transition: height 0.1s cubic-bezier(0.44, 0, 1, 1); }

.Media-time-hovered.no-hover {
  transform: scaleX(0) !important; }

.Media-time-handle,
.Media-time-handle-content {
  border: 4px solid transparent;
  cursor: pointer;
  left: 0;
  position: absolute;
  transform: translateX(0);
  z-index: 11; }

.Media-time-handle-content {
  border: 4px solid rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  height: 10px;
  left: -7px;
  top: -4px;
  transform: scale(0);
  width: 10px; }

.Media-time-rail:hover .Media-time-handle-content,
.Media-time-rail .Media-time-handle-content:focus,
.Media-time-rail .Media-time-handle-content:active {
  transform: scale(1); }

.Media-time-float {
  background: #eee;
  border: solid 1px #333;
  bottom: 100%;
  color: #111;
  display: none;
  height: 17px;
  margin-bottom: 9px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 36px; }

.Media-time-float-current {
  display: block;
  left: 0;
  margin: 2px;
  text-align: center;
  width: 30px; }

.Media-time-float-corner {
  border: solid 5px #eee;
  border-color: #eee transparent transparent;
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  line-height: 0;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  width: 0; }

.Media-long-video .Media-time-float {
  margin-left: -23px;
  width: 64px; }

.Media-long-video .Media-time-float-current {
  width: 60px; }

.Media-broadcast {
  color: #fff;
  height: 10px;
  position: absolute;
  top: 15px;
  width: 100%; }

/* End: Progress Bar */
/* Start: Fullscreen */
.Media-fullscreen-button > button {
  background-position: -80px 0; }

.Media-unfullscreen > button {
  background-position: -100px 0; }

/* End: Fullscreen */
/* Start: Mute/Volume */
.Media-mute > button {
  background-position: -60px 0; }

.Media-unmute > button {
  background-position: -40px 0; }

.Media-volume-button {
  position: relative; }

.Media-volume-button > .Media-volume-slider {
  -webkit-backface-visibility: hidden;
  background: rgba(50, 50, 50, 0.7);
  border-radius: 0;
  bottom: 100%;
  display: none;
  height: 115px;
  left: 50%;
  margin: 0;
  position: absolute;
  transform: translateX(-50%);
  width: 25px;
  z-index: 1; }

.Media-volume-button:hover {
  border-radius: 0 0 4px 4px; }

.Media-volume-total {
  background: rgba(255, 255, 255, 0.5);
  height: 100px;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 8px;
  transform: translateX(-50%);
  width: 2px; }

.Media-volume-current {
  background: rgba(255, 255, 255, 0.9);
  left: 0;
  margin: 0;
  position: absolute;
  width: 100%; }

.Media-volume-handle {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 1px;
  cursor: ns-resize;
  height: 6px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 16px; }

.Media-horizontal-volume-slider {
  display: block;
  height: 36px;
  position: relative;
  vertical-align: middle;
  width: 56px; }

.Media-horizontal-volume-total {
  background: rgba(50, 50, 50, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 8px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 16px;
  width: 50px; }

.Media-horizontal-volume-current {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.Media-horizontal-volume-handle {
  display: none; }

/* End: Mute/Volume */
/* Start: Track (Captions and Chapters) */
.Media-captions-button,
.Media-chapters-button {
  position: relative; }

.Media-captions-button > button {
  background-position: -140px 0; }

.Media-chapters-button > button {
  background-position: -180px 0; }

.Media-captions-button > .Media-captions-selector,
.Media-chapters-button > .Media-chapters-selector {
  background: rgba(50, 50, 50, 0.7);
  border: solid 1px transparent;
  border-radius: 0;
  bottom: 100%;
  margin-right: -43px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 50%;
  visibility: visible;
  width: 86px; }

.Media-chapters-button > .Media-chapters-selector {
  margin-right: -55px;
  width: 110px; }

.Media-captions-selector-list,
.Media-chapters-selector-list {
  list-style-type: none !important;
  margin: 0;
  overflow: hidden;
  padding: 0; }

.Media-captions-selector-list-item,
.Media-chapters-selector-list-item {
  color: #fff;
  cursor: pointer;
  display: block;
  list-style-type: none !important;
  margin: 0 0 6px;
  overflow: hidden;
  padding: 0; }

.Media-captions-selector-list-item:hover,
.Media-chapters-selector-list-item:hover {
  background-color: #c8c8c8 !important;
  background-color: rgba(255, 255, 255, 0.4) !important; }

.Media-captions-selector-input,
.Media-chapters-selector-input {
  clear: both;
  float: left;
  left: -1000px;
  margin: 3px 3px 0 5px;
  position: absolute; }

.Media-captions-selector-label,
.Media-chapters-selector-label {
  cursor: pointer;
  float: left;
  font-size: 10px;
  line-height: 15px;
  padding: 4px 10px 0;
  width: 100%; }

.Media-captions-selected,
.Media-chapters-selected {
  color: #21f8f8; }

.Media-captions-translations {
  font-size: 10px;
  margin: 0 0 5px; }

.Media-captions-layer {
  bottom: 0;
  color: #fff;
  font-size: 16px;
  left: 0;
  line-height: 20px;
  position: absolute;
  text-align: center; }

.Media-captions-layer a {
  color: #fff;
  text-decoration: underline; }

.Media-captions-layer[lang=ar] {
  font-size: 20px;
  font-weight: normal; }

.Media-captions-position {
  bottom: 15px;
  left: 0;
  position: absolute;
  width: 100%; }

.Media-captions-position-hover {
  bottom: 35px; }

.Media-captions-text,
.Media-captions-text * {
  background: rgba(20, 20, 20, 0.5);
  box-shadow: 5px 0 0 rgba(20, 20, 20, 0.5), -5px 0 0 rgba(20, 20, 20, 0.5);
  padding: 0;
  white-space: pre-wrap; }

.Media-container.Media-hide-cues video::-webkit-media-text-track-container {
  display: none; }

/* End: Track (Captions and Chapters) */
/* Start: Error */
.Media-overlay-error {
  position: relative; }

.Media-overlay-error > img {
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
  z-index: -1; }

.Media-cannotplay,
.Media-cannotplay a {
  color: #fff;
  font-size: 0.8em; }

.Media-cannotplay {
  position: relative; }

.Media-cannotplay p,
.Media-cannotplay a {
  display: inline-block;
  padding: 0 15px;
  width: 100%; }

/* Grid */
/**
 * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
 * widths and offsets should be applied to child grid cells.
 */
.Grid {
  box-sizing: border-box;
  display: flex;
  /* 1 */
  flex-flow: row wrap;
  margin: 0;
  /* 1 */
  padding: 0;
  /* 1 */ }

/**
    * No explicit width by default. Rely on combining `Grid-cell` with a dimension
    * utility or a component class that extends 'Grid'.
    *
    * 1. Set flex items to full width by default
    * 2. Fix issue where elements with overflow extend past the
    *      `Grid-cell` container - https://git.io/vw5oF
    */
.Grid-cell {
  box-sizing: inherit;
  flex-basis: 100%;
  /* 1 */
  min-width: 0;
  /* 2 */ }

/**
    * Modifiers
    */
.Grid--alignCenter {
  justify-content: center; }

.Grid--alignRight {
  justify-content: flex-end; }

.Grid--alignMiddle {
  align-items: center; }

.Grid--alignBottom {
  align-items: flex-end; }

.Grid--fill > .Grid-cell {
  flex: 1 1 0%;
  /* 1 */ }

.Grid--fit > .Grid-cell {
  flex-basis: auto; }

.Grid--equalHeight > .Grid-cell {
  display: flex; }

.Grid--withExtraSmallGutter {
  margin-left: -8px; }
  .Grid--withExtraSmallGutter > .Grid-cell {
    padding-left: 8px; }

.Grid--withSmallGutter {
  margin-left: -16px; }
  .Grid--withSmallGutter > .Grid-cell {
    padding-left: 16px; }

.Grid--withGutter {
  margin-left: -32px; }
  .Grid--withGutter > .Grid-cell {
    padding-left: 32px; }

.Grid--withLargeGutter {
  margin-left: -64px; }
  .Grid--withLargeGutter > .Grid-cell {
    padding-left: 64px; }

.Grid--withExtraLargeGutter {
  margin-left: -128px; }
  .Grid--withExtraLargeGutter > .Grid-cell {
    padding-left: 128px; }

.Grid--withExtraExtraLargeGutter {
  margin-left: -128px; }
  .Grid--withExtraExtraLargeGutter > .Grid-cell {
    padding-left: 128px; }

/* Icon */
.Icon {
  display: inline-flex;
  align-self: center;
  flex-shrink: 0; }
  .Icon svg {
    fill: currentColor;
    height: 1em;
    width: 1em; }
  .Icon.Icon--baseline svg {
    top: 0.125em;
    position: relative; }

/* Structure */
.Container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1100px;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
  width: 100%; }
  .Container--xxl {
    max-width: 1960px;
    padding-right: 100px;
    padding-left: 100px; }
  .Container--xl {
    max-width: 1480px; }
  .Container--lg {
    max-width: 1320px; }
  .Container--md {
    max-width: 1100px; }
  .Container--sm {
    max-width: 1000px; }
  .Container--xs {
    max-width: 640px; }
  @media screen and (min-width: 111.25em) {
    .Container {
      max-width: 1140px;
      padding-right: 40px;
      padding-left: 40px; }
      .Container--xxl {
        max-width: 2000px;
        padding-right: 360px;
        padding-left: 360px; }
      .Container--xl {
        max-width: 1520px; }
      .Container--lg {
        max-width: 1360px; }
      .Container--md {
        max-width: 1140px; }
      .Container--sm {
        max-width: 1040px; }
      .Container--xs {
        max-width: 680px; } }
  @media screen and (min-width: 20em) and (max-width: 37.5625em) {
    .Container {
      max-width: 1140px;
      padding-right: 40px;
      padding-left: 40px; }
      .Container--xxl {
        max-width: 2000px;
        padding-right: 20px;
        padding-left: 20px; }
      .Container--xl {
        max-width: 1520px; }
      .Container--lg {
        max-width: 1360px; }
      .Container--md {
        max-width: 1140px; }
      .Container--sm {
        max-width: 1040px; }
      .Container--xs {
        max-width: 680px; } }

body {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "header" "main" "footer"; }

.header {
  position: relative;
  grid-area: header;
  height: 100%; }

.footer {
  position: relative;
  grid-area: footer; }

.main {
  position: relative;
  grid-area: main; }
  .main > .Container {
    padding-top: 32px;
    padding-bottom: 32px; }
    @media screen and (min-width: 48em) {
      .main > .Container {
        grid-gap: 64px; }
        .main > .Container.has-leftSide {
          display: grid;
          grid-template-columns: 33.33333% 1fr;
          grid-template-rows: auto;
          grid-template-areas: "left content"; }
        .main > .Container.has-rightSide {
          display: grid;
          grid-template-columns: 1fr 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "content right"; }
        .main > .Container.has-sides {
          display: grid;
          grid-template-columns: 33.33333% auto 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "left content right"; } }

.Side {
  position: relative; }
  @media screen and (max-width: 47.9375em) {
    .Side {
      margin-top: 32px; } }
  @media screen and (min-width: 48em) {
    .Side--left {
      grid-area: left; }
    .Side--right {
      grid-area: right; } }

.Content {
  grid-area: content;
  position: relative; }

/* Table */
.Table {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  table-layout: fixed;
  text-align: left;
  width: 100%; }
  .Table th,
  .Table td {
    vertical-align: middle;
    padding: 5.33333px 10.66667px 5.33333px 10.66667px; }
  .Table thead {
    background-color: #f5f5f5;
    border-bottom: 2px solid #e0e0e0; }
  .Table tbody tr + tr {
    border-top: 1px solid #e0e0e0; }
  .Table tfoot {
    background-color: #f5f5f5;
    border-top: 2px solid #e0e0e0; }
  @media screen and (max-width: 47.9375em) {
    .Table.is-responsive {
      border-width: 0; }
      .Table.is-responsive,
      .Table.is-responsive tbody,
      .Table.is-responsive tfoot,
      .Table.is-responsive tr {
        display: block; }
      .Table.is-responsive thead {
        display: none; }
      .Table.is-responsive tbody tr {
        border: 1px solid #e0e0e0;
        border-bottom-width: 0; }
      .Table.is-responsive tfoot {
        border: 1px solid #e0e0e0;
        border-top-width: 2px; }
      .Table.is-responsive .Table-cell {
        display: inline-block;
        vertical-align: top;
        width: 100%; }
        .Table.is-responsive .Table-cell[data-table-label]::before {
          content: attr(data-table-label);
          display: block;
          margin: -16px -16px 16px;
          padding: 16px;
          background-color: #fafafa;
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0; } }

.Table--striped tbody tr:nth-child(even) {
  background-color: #fafafa; }

@media screen and (max-width: 47.9375em) {
  .Table--striped.is-responsive tbody tr:nth-child(even) {
    background-color: transparent; } }

.Toggle {
  height: 24px;
  width: 30px;
  position: relative;
  cursor: pointer;
  transform: rotate(0deg);
  z-index: 999999999; }
  .Toggle-item {
    height: 2px;
    width: 100%;
    position: absolute;
    transition-property: top, transform, opacity;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
    background-color: #000000;
    border-radius: 0px;
    display: block;
    transform: rotate(0deg); }
    .Toggle-item:nth-child(1) {
      top: 0; }
      .show-nav .Toggle-item:nth-child(1) {
        top: 11px;
        background-color: #000000;
        transform: rotate(135deg); }
    .Toggle-item:nth-child(2) {
      top: 11px; }
      .show-nav .Toggle-item:nth-child(2) {
        opacity: 0; }
    .Toggle-item:nth-child(3) {
      top: 22px; }
      .show-nav .Toggle-item:nth-child(3) {
        top: 11px;
        background-color: #000000;
        transform: rotate(-135deg); }

/* Cookie */
.CookieConsent {
  background-color: #000000;
  color: #ffffff;
  padding: 32px;
  transition-property: transform, opacity, visibility;
  transition-duration: .2s;
  transition-delay: 0s, .2s, .2s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 900;
  opacity: .9; }
  .CookieConsent p {
    margin-bottom: 0;
    font-size: 14px; }

.CookieConsent:not(.is-active) {
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden; }

.CookieConsent > .Container {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.cookie-text {
  font-size: 15px; }

.CookieConsent a {
  font-weight: bold;
  margin-left: 5px;
  color: #F4F4F4;
  text-decoration: underline; }
  .CookieConsent a, .CookieConsent a:active, .CookieConsent a:focus, .CookieConsent a:hover {
    color: #ffffff; }

.Close {
  cursor: pointer;
  line-height: 1;
  margin-left: 20px;
  transition: opacity .2s;
  border: 1px solid white;
  color: #272424;
  font-size: 14px;
  padding: 8px 12px; }
  .Close:not(:hover) {
    opacity: .75; }

.header {
  position: relative;
  height: 100vh;
  background-image: url("../images/header.svg");
  background-repeat: repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center; }
  .header__nav {
    position: absolute;
    top: 32px;
    right: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    @media screen and (min-width: 48em) {
      .header__nav {
        top: 64px;
        right: 64px;
        flex-direction: row; } }
    .header__nav__item {
      text-transform: lowercase;
      color: white;
      text-shadow: 1px 1px 1px #00000063; }
      .header__nav__item:hover {
        color: #F4F4F4; }
      .header__nav__item.is-active {
        display: none; }
    .header__nav__item + .header__nav__item {
      margin-top: 16px; }
      @media screen and (min-width: 48em) {
        .header__nav__item + .header__nav__item {
          margin-top: 0;
          margin-left: 16px; } }
  .header__banner {
    flex: 1;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px 0;
    width: 100%; }
    @media screen and (min-width: 48em) {
      .header__banner {
        padding: 64px; } }
  .header__logo {
    width: 100%;
    max-width: 80%;
    height: 64px; }
    @media screen and (min-width: 48em) {
      .header__logo {
        height: 96px; } }
    @media screen and (min-width: 64em) {
      .header__logo {
        height: 160px; } }
    .header__logo svg {
      width: 100%;
      height: 100%; }
    .header__logo--small {
      max-width: 50%;
      height: 48px; }
  .header__wrap {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center; }
  .header__cta {
    margin-top: 64px; }
  .header__scroll {
    position: absolute;
    left: 50%;
    bottom: 32px;
    transform: translateX(-50%);
    text-align: center;
    text-decoration: none;
    animation: arrow-bounce 1s infinite; }

@keyframes arrow-bounce {
  0% { }
  50% {
    bottom: 40px; }
  100% {
    bottom: 32px; } }
    .header__scroll__icon {
      transform: rotate(90deg);
      margin-bottom: 16px; }
    .header__scroll__label {
      font-size: 13px;
      color: white; }

.footer {
  border-top: 1px solid #F1EDED;
  color: #B4ADAD;
  padding: 64px 0; }
  @media screen and (max-width: 47.9375em) {
    .footer__item {
      line-height: 2;
      margin-right: 32px;
      padding-top: 16px; } }
  @media screen and (min-width: 48em) {
    .footer {
      padding: 64px; }
      .footer__wrap {
        display: flex;
        justify-content: space-between; }
      .footer__item + .footer__item {
        margin-right: 0;
        margin-left: 32px; } }
  .footer__right {
    margin-top: 32px; }
    @media screen and (min-width: 48em) {
      .footer__right {
        margin-top: 0; } }

.main {
  padding-top: 160px;
  padding-bottom: 160px;
  color: #272424; }

.section + .section {
  margin-top: 224px; }

.intro {
  text-align: center; }
  .intro__title {
    color: #EFEDED;
    margin-bottom: 64px; }
  .intro__text {
    margin: 0 auto; }
    @media screen and (min-width: 48em) {
      .intro__text {
        max-width: 65%; } }

.steps .step + .step {
  margin-top: 128px; }

.step {
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 48em) {
    .step {
      flex-direction: row; }
      .step--switch {
        flex-direction: row-reverse; }
        .step--switch .step__wrap {
          padding: 32px 0 32px 64px; } }
  .step > * {
    flex: 1; }
  .step:nth-child(3n+1) .step__index, .step:nth-child(3n+1) .step__title {
    color: #084E38; }
  .step:nth-child(3n+2) .step__index, .step:nth-child(3n+2) .step__title {
    color: #D09023; }
  .step:nth-child(3n+3) .step__index, .step:nth-child(3n+3) .step__title {
    color: #5784A9; }
  .step__content {
    display: flex;
    align-items: center; }
  @media screen and (min-width: 48em) {
    .step__wrap {
      padding: 32px 64px 32px 0; } }
  @media screen and (min-width: 64em) {
    .step__wrap {
      padding: 64px;
      max-width: 50%; } }
  .step__image__image {
    width: 100%;
    height: unset; }
  .step__index {
    opacity: .3; }
  .step__title {
    margin-bottom: 32px; }

.button--icon {
  display: inline-flex;
  align-items: center;
  font-weight: 500; }
  .button--icon:hover {
    color: inherit; }
  .button--icon .button__icon {
    margin-right: 16px; }
  .button--icon .button__label {
    white-space: nowrap; }

.button--filled {
  background: white;
  color: #272424;
  border-radius: 48px;
  padding: 8px 32px 8px 8px; }
  .button--filled .button__label {
    padding-top: 3px; }
  .button--filled svg {
    display: block; }
    .button--filled svg path {
      fill: #272424; }

.button--bold {
  font-size: 20px;
  line-height: 1;
  font-weight: bold; }

.cta {
  background: #272424;
  color: white;
  margin-left: -32px;
  margin-right: -32px; }
  @media screen and (min-width: 64em) {
    .cta {
      margin-left: -128px;
      margin-right: -128px; } }
  @media screen and (min-width: 87.5em) {
    .cta {
      display: grid;
      grid-template-columns: 2fr 1fr; } }
  .cta__left, .cta__right {
    padding: 64px 32px; }
    @media screen and (min-width: 48em) {
      .cta__left, .cta__right {
        padding: 64px; } }
    @media screen and (min-width: 87.5em) {
      .cta__left, .cta__right {
        padding: 64px 128px; } }
  .cta__left {
    position: relative;
    padding-bottom: 0; }
    @media screen and (min-width: 64em) {
      .cta__left {
        padding-bottom: 64px;
        padding-right: 256px; } }
  .cta__title {
    margin-bottom: 32px; }
  .cta__image {
    width: 100%;
    height: 350px;
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 64px; }
    @media screen and (min-width: 64em) {
      .cta__image {
        width: 450px;
        position: absolute;
        right: 32px;
        bottom: 0;
        height: 90%;
        background-position: bottom right;
        margin: 0; } }
    @media screen and (min-width: 87.5em) {
      .cta__image {
        right: -32px; } }
  .cta__right {
    background: #CBD9E4;
    display: flex;
    align-items: center; }
  .cta__steps {
    white-space: nowrap; }
    .cta__steps .title {
      line-height: 72px; }

.outro {
  display: flex;
  flex-direction: column-reverse; }
  @media screen and (min-width: 64em) {
    .outro {
      display: grid;
      grid-template-columns: 2fr 1fr; } }
  @media screen and (min-width: 64em) {
    .outro__left {
      padding-right: 96px; } }
  .outro__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 64px; }
    @media screen and (min-width: 64em) {
      .outro__right {
        margin-bottom: 0; } }
  .outro__title {
    margin-bottom: 32px;
    color: #EFEDED; }
  .outro__text {
    margin-bottom: 16px; }

.banner {
  background: #272424;
  color: white;
  margin-left: -32px;
  margin-right: -32px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  @media screen and (min-width: 64em) {
    .banner {
      margin-left: -128px;
      margin-right: -128px; } }
  @media screen and (min-width: 87.5em) {
    .banner {
      display: grid;
      grid-template-columns: 2fr 1fr; } }
  .banner__left, .banner__right {
    padding: 64px 32px; }
    @media screen and (min-width: 48em) {
      .banner__left, .banner__right {
        padding: 64px; } }
    @media screen and (min-width: 87.5em) {
      .banner__left, .banner__right {
        padding: 64px 128px; } }
  .banner__left {
    position: relative;
    padding-bottom: 0; }
    @media screen and (min-width: 64em) {
      .banner__left {
        padding-bottom: 64px;
        padding-right: 256px; } }
  .banner__title {
    margin-bottom: 16px; }
  .banner__button {
    margin-top: 64px; }
  .banner__right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end; }
  .banner__logo {
    width: 220px; }

.categories {
  display: grid;
  column-gap: 16px;
  row-gap: 48px;
  grid-template-columns: 1fr 1fr; }
  @media screen and (min-width: 80em) {
    .categories {
      grid-template-columns: 1fr 1fr 1fr; } }

.category__image {
  width: 100%;
  margin-bottom: 10.66667px; }

.category__title {
  font-weight: bold; }

.tags {
  display: grid; }
  .tags__image, .tags__overlay {
    grid-area: 1/2; }
  .tags__overlay {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    padding: 16px; }
  .tags__item {
    display: flex;
    font-size: 10px;
    font-weight: bold;
    justify-content: center;
    align-items: center; }
    .tags__item[data-horizontal='left'] {
      grid-column: 1;
      justify-content: flex-start; }
    .tags__item[data-horizontal='center'] {
      grid-column: 2;
      justify-content: center; }
    .tags__item[data-horizontal='right'] {
      grid-column: 3;
      justify-content: flex-end; }
    .tags__item[data-vertical='top'] {
      grid-row: 1;
      align-items: flex-start; }
    .tags__item[data-vertical='center'] {
      grid-row: 2;
      align-items: center; }
    .tags__item[data-vertical='bottom'] {
      grid-row: 3;
      align-items: flex-end; }

.hamburger {
  position: absolute;
  top: 64px;
  right: 64px;
  width: 40px;
  display: inline-block;
  cursor: pointer;
  z-index: 9999; }
  .hamburger.active .hamburger__inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    .hamburger.active .hamburger__inner::before {
      transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
    .hamburger.active .hamburger__inner::after {
      transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .hamburger.active .hamburger__inner::before {
      top: 0;
      opacity: 0;
      transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
    .hamburger.active .hamburger__inner::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger__box {
    width: 40px;
    height: 20px;
    padding-top: 12px;
    display: inline-block;
    position: relative; }
  .hamburger__inner {
    display: block; }
    .hamburger__inner, .hamburger__inner::before, .hamburger__inner::after {
      width: 40px;
      height: 1px;
      background-color: white;
      border-radius: 0;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
      transition: all .3s ease; }
    .hamburger__inner::before, .hamburger__inner::after {
      content: "";
      display: block; }
    .hamburger__inner::before {
      top: -11px; }
    .hamburger__inner::after {
      bottom: -11px; }
  .hamburger__label {
    color: white;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: center; }
    .hamburger__label__open {
      display: inline; }
      .menu-open .hamburger__label__open {
        display: none; }
    .hamburger__label__close {
      display: none; }
      .menu-open .hamburger__label__close {
        display: inline; }

.menu-open {
  overflow: hidden; }

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: -1;
  transition: opacity .3s ease;
  position: fixed;
  background: #272424;
  color: white;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  overflow: scroll; }
  .menu-open .nav {
    opacity: 1;
    z-index: 99; }
  .nav__wrap {
    width: 100%; }
    .nav__wrap > * {
      padding-top: 64px;
      padding-bottom: 64px;
      max-width: unset; }
  .nav__language__item {
    text-transform: lowercase;
    margin-right: 16px;
    color: #b4adad;
    transition: color .3s ease; }
    .nav__language__item:hover {
      color: #cecece; }
    .nav__language__item.is-active {
      display: none; }
  .nav__pages {
    padding-top: 0;
    display: flex;
    flex-direction: column; }
    .nav__pages__item {
      color: #646262;
      display: inline-block;
      line-height: 1;
      font-size: 39px;
      line-height: 1.2;
      transition: color .3s ease;
      font-weight: bold;
      padding-bottom: 2rem; }
      @media screen and (min-width: 48em) {
        .nav__pages__item {
          font-size: 60px;
          line-height: 1.2; } }
      .nav__pages__item:hover {
        color: white; }
  .nav__footer {
    border-top: 1px solid white; }
    .nav__footer__item {
      margin-right: 16px;
      color: #b4adad;
      transition: color .3s ease; }
      .nav__footer__item:hover {
        color: #cecece; }
      .nav__footer__item.is-active {
        display: none; }
