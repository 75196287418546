.wrap {
    position: relative;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0 auto;
    padding: 0 u($rule);
    max-width: 1500px;
    box-sizing: content-box;
    
    @include mq($from: $viewport--sm) {
        padding: 0 10%;
    }

    @include mq($from: $viewport--xxl) {
        padding: 0 15%;
    }
    
    &--large {
        max-width: 1650px;

        @include mq($from: $viewport--sm) {
            padding: 0 6%;
        }

        @include mq($from: $viewport--xxl) {
            padding: 0 10%;
        }

    }

    &--small {

        @include mq($from: $viewport--sm) {
            padding: 0 15%;
        }

        @include mq($from: $viewport--xxl) {
            padding: 0 25%;
        }
        
    }

    .wrap { 
        @include mq($until: $viewport--sm--until) {
            padding: 0;
        }

        &--small {
            @include mq($from: $viewport--sm) {
                padding: 0 15%;
            }

            @include mq($from: $viewport--lg) {
                padding: 0 20%;
            }
        }
    }

}


.main {
    &--first-child {
        
        .section {
        
            &:first-child {
                position: relative;
        
                &:before {
                    content: '';
                    position: absolute;
                    top: -#{u($rule)};
                    left: -#{u($rule/2)};
                    right: -#{u($rule/2)};
                    height: u($rule);
                    
                    z-index: 99;
                    
                    margin: 0 u($rule);
                    box-sizing: border-box; 
                    
                    @include mq($from: $viewport--md) {
                        top: -#{u($rule*2)};
                        height: u($rule*2);
                        left: 0;
                        right: 0;
                        margin: 0 5%;
                    }
        
                    @include mq($from: $viewport--xxl) {
                        margin: 0 auto;
                        padding: 0 10%;
        
                        max-width: 1500px;
                        box-sizing: content-box; 
                    }
        
                } 
            } 
        
        }

    } 
}