.intro {
    text-align: center;

    &__title {
        color: #EFEDED;
        margin-bottom: u($rule*2);
    }

    &__text {
        @include mq($from: $viewport--md) {
            max-width: 65%;
        }
        margin: 0 auto;
    }
}