/* Heading */
.title {
    font-family: $cc-font;
    font-weight: bold;
    font-style: normal;
    word-break: break-word;
    
    .fonts-loaded & {
        font-family: $cc-font;
    }
    
    small {
        font-weight: 500;
    }

    @include font-size($heading-size-1*0.48, false);

    @include mq($from: $viewport--md) {
        @include font-size($heading-size-1, false);
    }
}

.title--beta {
    @include font-size($heading-size-1*0.48, false);

    @include mq($from: $viewport--md) {
        @include font-size($heading-size-1, false);
    }
}

.title--charlie {
    @include font-size($heading-size-2*0.65, false);

    @include mq($from: $viewport--md) {
        @include font-size($heading-size-2, false);
    }
}

.title--delta {
    @include font-size($heading-size-3, false);
}

.title--echo {
    @include font-size($heading-size-4, false);
}

.title--foxtrot {
    @include font-size($heading-size-5, false);
}
