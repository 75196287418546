.footer {
    border-top: 1px solid #F1EDED; 
    color: $cc-grey-text;
    padding: u($rule*2) 0;

    @include mq($until: $viewport--md--until) {
        &__item {
            line-height: 2;
            margin-right: u($rule);
            padding-top: u($rule/2);
        }
    }

    @include mq($from: $viewport--md) {
        padding: u($rule*2);

        &__wrap {
            display: flex;
            justify-content: space-between;
        }

        &__item + &__item {
            margin-right: 0;
            margin-left: u($rule);
        }
    }

    &__left {

    }

    &__right {
        margin-top: u($rule);

        @include mq($from: $viewport--md) {
            margin-top: 0;
        }
    }
}