/* Link */
a {
    @include transition(#{background-color, color});

    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: $cc-black;
    }
}

.link {
    @include transition(#{background-color, color});
    color: $link-color;
    text-decoration: $link-text-decoration;

    padding-bottom: 5px;
    // border-bottom: 1px solid $cc-black;

    position: relative;

    font-weight: 500;
    
    &:before{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: $cc-black;
        content: '';
        transition: transform .7s cubic-bezier(.19, 1, .22, 1), background .6s ease;
        transform: scaleX(1);
        transform-origin: left;
    }

    &:hover,
    &:focus,
    &:active {

        &:before {
            transform: scaleX(0);
            transform-origin: right;
        }

        color: $link-color--hover;
        text-decoration: $link-text-decoration--hover;
    }
}
