.banner {
    $spacing: $rule*4;

    background: $cc-black;
    color: $cc-white;
    
    margin-left: -#{u($rule)};
    margin-right: -#{u($rule)};

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include mq($from: $viewport--lg) {
        margin-left: -#{u($spacing)};
        margin-right: -#{u($spacing)};
    }

    @include mq($from: 1400) {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }

    &__left, &__right {
        padding: u($rule*2) u($rule);
        
        @include mq($from: $viewport--md) {
            padding: u($rule*2);
        } 

        @include mq($from: 1400) {
            padding: u($rule*2) u($spacing);
        }
    }
    
    &__left {        
        position: relative;
        padding-bottom: 0;

        @include mq($from: $viewport--lg) {
            padding-bottom: u($rule*2);
            padding-right: u($spacing*2);
        }
    }

    &__title {
        margin-bottom: u($rule/2);
    }

    &__button {
        margin-top: u($rule*2);
    }

    &__right {
        
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        
    }

    &__logo {
        width: 220px;
    }
    

}