.categories {
    display: grid;
    column-gap: u($rule/2);
    row-gap: u($rule*1.5);
    grid-template-columns: 1fr 1fr;

    @include mq($from: $viewport--xl) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.category {
    &__image {
        width: 100%;
        margin-bottom: u($rule/3);
    }

    &__title {
        font-weight: bold;
    }
}