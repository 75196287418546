/* Image */

// Fluid
img {
    @include image-rendering($image-rendering);
    max-width: 100%;
    height: auto;
}


// Figure
figure > img {
    display: block;

    &.img--cover {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}
