.button {
    $self: &;

    &--icon {

        display: inline-flex; 
        align-items: center;
        font-weight: 500;

        &:hover {
            color: inherit;
        }

        #{$self}__icon {
            margin-right: u($rule/2);
        }

        #{$self}__label {
            white-space: nowrap;
        }
    }

    &--filled {
        background: $cc-white; 
        color: $cc-black;
        border-radius: u($rule*1.5);
        padding: u($rule/4) u($rule) u($rule/4) u($rule/4);


        #{$self}__label {
            padding-top: 3px;
        }

        svg { 
            display: block;

            path {
                fill: $cc-black;
            }
        }
    }

    &--bold {
        font-size: 20px;
        line-height: 1;
        font-weight: bold;
    }
}