.cta {
    $spacing: $rule*4;

    
    background: $cc-black;
    color: $cc-white;
    
    margin-left: -#{u($rule)};
    margin-right: -#{u($rule)};

    @include mq($from: $viewport--lg) {
        margin-left: -#{u($spacing)};
        margin-right: -#{u($spacing)};
    }

    @include mq($from: 1400) {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }

    &__left, &__right {
        padding: u($rule*2) u($rule);
        
        @include mq($from: $viewport--md) {
            padding: u($rule*2);
        } 

        @include mq($from: 1400) {
            padding: u($rule*2) u($spacing);
        }
    }
    
    &__left {        
        position: relative;
        padding-bottom: 0;

        @include mq($from: $viewport--lg) {
            padding-bottom: u($rule*2);
            padding-right: u($spacing*2);
        }
    }

    &__title {
        margin-bottom: u($rule);
    }

    &__image {
        width: 100%;
        height: 350px;
        
        background-position: bottom center;
        background-size: contain;
        background-repeat: no-repeat;

        margin-top: u($rule*2);
        
        @include mq($from: $viewport--lg) {
            width: 450px;
            position: absolute;
            
            right: u($rule);
            bottom: 0;
            
            height: 90%;
            background-position: bottom right;
            margin: 0;
        }

        @include mq($from: 1400) {
            right: -#{u($rule)};
        }
    }

    &__right {
        background: $cc-blue-light;

        display: flex;
        align-items: center;

        
    }

    &__steps {
        white-space: nowrap;
        
        .title {
            line-height: u($rule*2.25);
        }
    }
}