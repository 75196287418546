.steps {
    .step + .step {
        margin-top: u($rule*4);
    }
}

.step {
    $self: &;

    display: flex; 
    flex-direction: column;
    
    @include mq($from: $viewport--md) {
        flex-direction: row;

        &--switch {
            flex-direction: row-reverse;

            #{$self}__wrap {
                padding: u($rule) 0 u($rule) u($rule*2);
            }

        }
    }


    > * {
        flex: 1;
    }

    &:nth-child(3n+1) {
        #{$self}__index, #{$self}__title {
            color: $cc-green;
        }
    }

    &:nth-child(3n+2) {
        #{$self}__index, #{$self}__title {
            color: $cc-orange;
        }
    }

    &:nth-child(3n+3) {
        #{$self}__index, #{$self}__title {
            color: $cc-blue;
        }
    }

    &__content {
        display: flex;
        align-items: center;
    }

    &__wrap {
        
        @include mq($from: $viewport--md) {
            padding: u($rule) u($rule*2) u($rule) 0;
        }
        
        @include mq($from: $viewport--lg) {
            padding: u($rule*2);
            max-width: 50%;
        }
    }

    &__image {

        &__image {
            width: 100%;
            height: unset;
        }

    }

    &__index {
        opacity: .3;
    }

    &__title {
        margin-bottom: u($rule);
    }

    &__text {

    }

    &__tags {

    }
}