/* Style */
@charset "UTF-8";

// 1. Settings
@import '1-settings/base';
@import '1-settings/colors';
@import '1-settings/fonts';

@import '1-settings/elements/heading';
@import '1-settings/elements/horizontal-rule';
@import '1-settings/elements/image';
@import '1-settings/elements/link';
@import '1-settings/elements/list';
@import '1-settings/elements/paragraph';
@import '1-settings/elements/selection';

@import '1-settings/objects/alert';
@import '1-settings/objects/button';
@import '1-settings/objects/flex-embed';
@import '1-settings/objects/form';
@import '1-settings/objects/grid';
@import '1-settings/objects/nav';
@import '1-settings/objects/structure';
@import '1-settings/objects/table';
@import '1-settings/objects/toggle';

@import '1-settings/components/fields';
@import '1-settings/components/modal';

// 2. Tools
@import '2-tools/functions/units';
@import '2-tools/functions/svg';
@import '2-tools/functions/throw';
@import '2-tools/functions/checks';
@import '2-tools/functions/truncate-list';
@import '2-tools/functions/maps';
@import '2-tools/functions/math';
@import '2-tools/functions/order-index';
@import '2-tools/functions/string';

@import '2-tools/mixins/arrow';
@import '2-tools/mixins/background-svg';
@import '2-tools/mixins/caret';
@import '2-tools/mixins/context';
@import '2-tools/mixins/dimensions';
@import '2-tools/mixins/family';
@import '2-tools/mixins/fluid-type';
@import '2-tools/mixins/font-size';
@import '2-tools/mixins/font-smoothing';
@import '2-tools/mixins/grid';
@import '2-tools/mixins/hover';
@import '2-tools/mixins/images';
@import '2-tools/mixins/last-children';
@import '2-tools/mixins/lhcrop';
@import '2-tools/mixins/maps';
@import '2-tools/mixins/parent-nth-status';
@import '2-tools/mixins/parent-state';
@import '2-tools/mixins/parent-status';
@import '2-tools/mixins/position';
@import '2-tools/mixins/queries';
@import '2-tools/mixins/transition';
@import '2-tools/mixins/truncate';

// 3. Generic
@import '3-generic/reset';

// 4. Vendors
@import '4-vendors/aos';
@import '4-vendors/fancybox';
@import '4-vendors/slick';

// 5. Elements
@import '5-elements/horizontal-rule';
@import '5-elements/image';
@import '5-elements/link';
@import '5-elements/list';
@import '5-elements/selection';
@import '5-elements/heading';
@import '5-elements/paragraph';
@import '5-elements/main';
@import '5-elements/wrap';
@import '5-elements/wysiwyg';

// 6. Objects
@import '6-objects/alert';
@import '6-objects/nav';
@import '6-objects/button';
@import '6-objects/flex-embed';
@import '6-objects/form';
@import '6-objects/media';
@import '6-objects/grid';
@import '6-objects/icon';
@import '6-objects/structure';
@import '6-objects/table';
@import '6-objects/toggle';

// 7. Components
@import '7-components/cookie';
@import '7-components/header';
@import '7-components/footer';
@import '7-components/main';
@import '7-components/section';
@import '7-components/intro';
@import '7-components/step';
@import '7-components/button';
@import '7-components/cta';
@import '7-components/outro';
@import '7-components/banner';
@import '7-components/categories';
@import '7-components/tags';
@import '7-components/hamburger';
@import '7-components/nav';
