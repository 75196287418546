.header {
  position: relative;

  height: 100vh; 

  background-image: url('#{$path-img}header.svg');       
  background-repeat: repeat;
  background-size: contain;
  background-position: center;

  display: flex;

  align-items: center;

  &__nav {
    position: absolute;

    top: u($rule);
    right: u($rule);
    
    display: flex; 
    flex-direction: column;
    align-items: flex-end;
    
    @include mq($from: $viewport--md) {
      top: u($rule*2);
      right: u($rule*2);

      flex-direction: row;
    }

    &__item {
      text-transform: lowercase;
      color: $cc-white;
      text-shadow: 1px 1px 1px #00000063;

      &:hover {
        color: $cc-grey;
      }

      &.is-active { 
        display: none;
      }
    }

    &__item + &__item {
      margin-top: u($rule/2);

      @include mq($from: $viewport--md) {
        margin-top: 0;
        margin-left: u($rule/2);
      }
    }

  }

  &__banner {
    flex: 1;
    background: $cc-white;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: u($rule) 0;

    width: 100%;

    @include mq($from: $viewport--md) {
      padding: u($rule*2);
    }
  }

  &__logo {
    width: 100%;
    max-width: 80%;
    height: u($rule*2);

    @include mq($from: $viewport--md) {
      height: u($rule*3);
    }

    @include mq($from: $viewport--lg) {
      height: u($rule*5);
    }

    svg { 
      width: 100%;
      height: 100%;
    }

    &--small {
      max-width: 50%;
      height: u($rule*1.5);
    }
  }

  &__wrap {

    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;

  }
  
  &__cta {
    margin-top: u($rule*2);
  }
  
  &__scroll {
    position: absolute;
    left: 50%;
    bottom: u($rule);
    transform: translateX(-50%);
    text-align: center;
    text-decoration: none;

    animation: arrow-bounce 1s infinite;

    @keyframes arrow-bounce {
      0% {
      }
      50% {
        bottom: u($rule + 8);
      }
      100% {
        bottom: u($rule);
      }
    }

    &__icon {
      transform: rotate(90deg);
      margin-bottom: u($rule/2);
    }

    &__label {
      font-size: 13px;
      color: $cc-white;
    }
  }

}