.menu-open {
    overflow: hidden;
}

.nav {

    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;
    z-index: -1;

    transition: opacity .3s ease;

    .menu-open & {
        opacity: 1;
        z-index: 99;
    }

    &__wrap {
        width: 100%;
        > * {
            padding-top: u($rule*2);
            padding-bottom: u($rule*2);
            max-width: unset;
        }
    }

    position: fixed;
    background: $cc-black; 
    color: $cc-white;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0; 

    height: 100vh;
    overflow: scroll; 


    &__language {
        &__item {
            text-transform: lowercase;
            margin-right: u($rule/2);
            color: #b4adad; 

            transition: color .3s ease;

            &:hover { 
                color: #cecece;
            }

            &.is-active {
                display: none;
            }
        }
    }

    &__pages {
        padding-top: 0;

        display: flex;
        flex-direction: column;

        &__item {
            color: #646262;
            display: inline-block;
            line-height: 1;
            @include font-size($heading-size-2*0.65, false);

            @include mq($from: $viewport--md) {
                @include font-size($heading-size-2, false);
            }
            
            transition: color .3s ease;
            font-weight: bold;
            padding-bottom: 2rem;

            &:hover {
                color: $cc-white;
            }
        }

    }

    &__footer {
        border-top: 1px solid $cc-white; 

        &__item {
            margin-right: u($rule/2);
            color: #b4adad; 

            transition: color .3s ease;

            &:hover { 
                color: #cecece;
            }

            &.is-active {
                display: none;
            }
        }
        
    }

}