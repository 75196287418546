.outro {
    display: flex;
    flex-direction: column-reverse;

    @include mq($from: $viewport--lg) {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }

    &__left {
        @include mq($from: $viewport--lg) {
            padding-right: u($rule*3);
        }
    }

    &__right {

        display: flex;
        flex-direction: column;
        justify-content: center;

        margin-bottom: u($rule*2);

        @include mq($from: $viewport--lg) {
            margin-bottom: 0;
        }

    }

    &__title {
        margin-bottom: u($rule);
        
        color: #EFEDED;
    }

    &__text {
        margin-bottom: u($rule/2);
    }
    

}