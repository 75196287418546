/* Colors: Custom */
//Example: $cc-[NAME]: #......

// [PROJECTNAME] colors
$cc-white: white;
$cc-lightestGrey: #f0f0f0 !default;
$cc-lightGrey: #F4F4F4 !default;
$cc-darkGrey: #888888 !default;
$cc-darkerGrey: #8B8B8B !default;
$cc-darkestGrey: #3B3B3B !default;
$cc-blackShade: #435572 !default;

$cc-black:#272424 !default;
$cc-grey: #F4F4F4 !default;
$cc-grey-text: #B4ADAD !default;
$cc-green: #084E38 !default;
$cc-orange: #D09023 !default;
$cc-blue: #5784A9 !default;
$cc-blue-light: #CBD9E4 !default;
